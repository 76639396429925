import {
  ACTIVATE_PUBLISHED_JOB,
  ALL_JOBS_COUNT,
  AUTOCOMPLETE_JOB_SEARCH,
  DELETE_JOB_SOFTLY,
  FETCH_MATCHING_CANDIDATES,
  GET_FAVORITE_CANDIDATES,
  INIT_RECRUITER,
  INTERVIEW_LISTING,
  JOBS_POSTED_BY_RECRUITER,
  JOBS_POSTED_BY_RECRUITER_LOADING,
  MATCHING_CANDIDATES,
  MEMBERS_JOBS_STATISTICS,
  POKE_CANDIDATE,
  POKE_CANDIDATE_LOADING,
  POST_JOB,
  POST_JOB_LIMIT_ERROR,
  RECRUITER_LOADING,
  REMOVE_TOP_JOBS_WITH_CANDIDATES,
  RESUME_CANDIDATE_INTERVIEW,
  SAVE_UPDATED_JOB_DESCRIPTION,
  START_CANDIDATE_INTERVIEW,
  TOP_JOBS_WITH_CANDIDATES,
  UPDATE_FAVORITE_CANDIDATE,
  UPDATE_JOB,
  UPDATE_MATCHING_CANDIDATES,
  UPDATE_TOP_MATCHING_CANDIDATES,
  UPDATE_INTERVIEW_STATUS,
  FETCH_INTERVIEW_HISTORY,
  IS_MATCHING_CANDIDATES_EXIST,
  ASSESSMENT_LISTING,
  ASSESSMENT_LISTING_FETCH_LOADING,
  ASSESSMENT_LISTING_DATA,
  JOB_LISTING_PAGINATION,
  JOB_LISTING_DATE_FILTER,
  JOB_LISTING_SEARCHED_JOB_FILTER,
  ZAPPYHIRE_JOB_POST,
  ZAPPYHIRE_CREATE_CANDIDATE,
  ZAPPYHIRE_SSO,
  APP_ENG_ASSESSMENT_LISTING,
  APP_ANNOTATION_ASSESSMENT_LISTING,
  RESET_ASSESSMENT,
  INCEPTION_STATS,
  UPDATE_COMPANY_SETTING,
  APP_ENG_ASSESSMENT_LISTING_V2,
  APP_ANNOTATION_ASSESSMENT_LISTING_V2,
  FETCH_OVERALL_ASSESSMENTS
} from "@/store/modules/recruiter/constants";
import {
  activate_published_job,
  fetch_assessment_listing,
  fetch_favorite_candidates,
  fetch_interview_history,
  fetch_interview_listing,
  fetch_members_jobs_stats,
  fetch_total_jobs_count,
  get_jobs_posted_by_recruiter,
  get_search_candidates,
  is_matching_candidates_exist,
  poke_candidate,
  post_job,
  resume_interview,
  save_updated_job_description,
  soft_delete_job,
  start_interview,
  update_favorite_candidate,
  update_interview_status,
  update_job,
  zappyhire_post_job,
  zappyhire_create_candidate,
  zappyhire_sso,
  fetch_app_assessment_listing,
  delete_assessment,
  inception_stats,
  update_company_setting,
  fetch_app_overall_result
} from "@/store/apis/recruiter";
import { Commit, Dispatch } from "vuex";
import { Jobs } from "@/interfaces/data_objects/jobs";
import {
  FetchAssessmentListingPayload,
  GetJobsPostedPayload,
  InterviewListing,
  InterviewListingPayload,
  // MatchingCandidatesByIdPayload,
  MembersJobsStatistics,
  PokeCandidatePayload,
  ResumeInterviewPayload,
  SaveUpdatedJobDescriptionPayload,
  SearchCandidatesAccumulator,
  SearchCandidatesPayload,
  SearchedCandidate,
  StartInterviewPayload,
  UpdateFavoriteCandidatePayload,
  UpdateInterviewStatusPayload,
  UpdateJobPayload,
  ZappyhireCreateCandidatePayload,
  ZapphirePostJobResponse,
  ZapphireCreateCandidateResponse,
  AppAssessmentListingPayload,
  AppEngAssessmentListing,
  DeleteAssessmentPayload,
  UpdateCompanySettingPayload,
  AppOverAllAssessmentListing
} from "@/store/modules/recruiter/interfaces";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { Interview } from "@/interfaces/responses/interviews/interviews";
import { ZappyhirePostjobPayload } from "@/interfaces/recruiter/jobs/post_job/zappyhire_post_job";
import { Company } from "../auth/interfaces";
import { AppAssessmentNames } from "../common/interfaces";
export default {
  [POST_JOB]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<unknown | null> => {
    try {
      commit(RECRUITER_LOADING, true);
      const response = await post_job(payload);
      commit(RECRUITER_LOADING, false);
      commit(JOB_LISTING_SEARCHED_JOB_FILTER, null);
      commit(JOB_LISTING_DATE_FILTER, []);
      commit(JOB_LISTING_PAGINATION, 1);
      return response.data.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.message.toString().includes("403")) {
        commit(
          POST_JOB_LIMIT_ERROR,
          "Limit Reached. Please contact at info@hcms.ai for more information"
        );
      }
      commit(RECRUITER_LOADING, false);
      return null;
    }
  },
  /**
   * Function to get jobs posted by recruiter
   * Jobs return in pagination
   * After getting jobs loop each job and fetch applicants separately
   * @param payload
   */
  [JOBS_POSTED_BY_RECRUITER]: async (
    { commit }: { commit: Commit },
    payload: GetJobsPostedPayload
  ): Promise<void> => {
    commit(JOBS_POSTED_BY_RECRUITER_LOADING, true);
    // await new Promise((resolve) => setTimeout(resolve, 3000));
    const response = await get_jobs_posted_by_recruiter(payload);
    let jobs = response.data.data;
    jobs = {
      results: jobs.results.map((v: Jobs) => ({
        ...v,
        matching_candidates: [],
        candidates_loading: true
      })),
      total: jobs.total
    };
    if (payload.dashboard) commit(TOP_JOBS_WITH_CANDIDATES, jobs);
    else commit(JOBS_POSTED_BY_RECRUITER, jobs);
    commit(JOBS_POSTED_BY_RECRUITER_LOADING, false);
  },
  /**
   * Function to get matching candidates/applicants against job id
   */
  [MATCHING_CANDIDATES]: async (
    { commit }: { commit: Commit },
    payload: SearchCandidatesPayload
  ): Promise<void> => {
    try {
      const response = await get_search_candidates(payload);
      const response_data = response.data.data;
      const candidates_job_based: SearchCandidatesAccumulator =
        response_data.results.reduce(
          (
            accumulator: SearchCandidatesAccumulator,
            obj: SearchedCandidate
          ) => {
            const jobId = obj.job_id;
            if (!jobId) return accumulator;
            if (!accumulator[jobId]) {
              accumulator[jobId] = [];
            }
            accumulator[jobId].push(obj);
            return accumulator;
          },
          {}
        );
      if (payload.dashboard) {
        commit(UPDATE_TOP_MATCHING_CANDIDATES, {
          candidates_job_based
        });
      } else {
        commit(UPDATE_MATCHING_CANDIDATES, {
          candidates_job_based
        });
      }
    } catch (e) {
      if (!payload.job_ids) return;
      const candidates_job_based: SearchCandidatesAccumulator = {};
      for (const job of payload.job_ids) {
        candidates_job_based[job] = [];
      }
      if (payload.dashboard) {
        commit(UPDATE_TOP_MATCHING_CANDIDATES, {
          candidates_job_based
        });
      } else {
        commit(UPDATE_MATCHING_CANDIDATES, {
          candidates_job_based
        });
      }
    }
  },
  /**
   * Function to softly delete job
   * @param job_id:number => jid to remove
   */
  [DELETE_JOB_SOFTLY]: async (
    { commit, getters }: { commit: Commit; getters: any },
    job_id: number
  ): Promise<boolean> => {
    try {
      commit(RECRUITER_LOADING, true);
      await soft_delete_job({ job_id });
      // Checking if job exist in top jobs data set
      const top_posted_jobs = getters.TOP_JOBS_WITH_CANDIDATES;
      const exist_job = top_posted_jobs.results.find(
        (v: Jobs) => v.jid === job_id
      );
      // If job exist in top jobs data set => remove & decrement total count
      if (exist_job) {
        commit(REMOVE_TOP_JOBS_WITH_CANDIDATES, job_id);
      }
      // If job not exist in top jobs data set => decrement total count only
      else commit(REMOVE_TOP_JOBS_WITH_CANDIDATES, -100);
      commit(RECRUITER_LOADING, false);
      return true;
    } catch (e) {
      commit(RECRUITER_LOADING, false);
      return false;
    }
  },
  /**
   * Function to get matching candidates/applicants
   */
  [FETCH_MATCHING_CANDIDATES]: async (
    { commit }: { commit: Commit },
    payload: SearchCandidatesPayload
  ): Promise<Candidates.Candidates[] | null> => {
    try {
      const response = await get_search_candidates(payload);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, null, { root: true });
      return null;
    }
  },
  /**
   * Function for autocomplete job search
   */
  [AUTOCOMPLETE_JOB_SEARCH]: async (
    { commit }: { commit: Commit },
    payload: GetJobsPostedPayload
  ): Promise<Jobs[]> => {
    try {
      const response = await get_jobs_posted_by_recruiter(payload);
      return response.data.data.results;
    } catch (e) {
      commit(ROOT_ERROR, null, { root: true });
      return [];
    }
  },
  /**
   * Initialize recruiter data
   */
  [INIT_RECRUITER]: async ({
    dispatch
  }: {
    dispatch: Dispatch;
  }): Promise<boolean> => {
    try {
      const fetch_top_candidates_obj = {
        page: 0,
        limit_per_page: 5,
        dashboard: true
      };
      await dispatch(JOBS_POSTED_BY_RECRUITER, fetch_top_candidates_obj);
      return true;
    } catch (e) {
      return false;
    }
  },
  /**
   * Action to activate published job
   */
  [ACTIVATE_PUBLISHED_JOB]: async (
    { commit }: { commit: Commit },
    job_id: number
  ): Promise<boolean> => {
    try {
      commit(RECRUITER_LOADING, true);
      await activate_published_job(job_id);
      commit(RECRUITER_LOADING, false);
      return true;
    } catch (e) {
      commit(RECRUITER_LOADING, false);
      return false;
    }
  },
  /**
   * Function to fetch all jobs count
   */
  [ALL_JOBS_COUNT]: async (): Promise<number> => {
    try {
      return (await fetch_total_jobs_count()).data.data;
    } catch (e) {
      return 0;
    }
  },
  /**
   * Function to update job
   */
  [UPDATE_JOB]: async (
    { commit }: { commit: Commit },
    payload: UpdateJobPayload
  ): Promise<boolean> => {
    try {
      commit(RECRUITER_LOADING, true);
      await update_job(payload);
      commit(RECRUITER_LOADING, false);
      return true;
    } catch (e) {
      commit(RECRUITER_LOADING, false);
      return false;
    }
  },
  [MEMBERS_JOBS_STATISTICS]: async (
    { commit }: { commit: Commit },
    payload: MembersJobsStatistics
  ): Promise<any> => {
    try {
      const response = await fetch_members_jobs_stats(payload);
      return response.data.data;
    } catch (e) {
      return false;
    }
  },
  /**
   * For getting favorite candidates against job
   */
  [GET_FAVORITE_CANDIDATES]: async (
    { commit }: { commit: Commit },
    job_id: number
  ): Promise<any> => {
    try {
      const response = await fetch_favorite_candidates(job_id);
      return response.data.data;
    } catch (e) {
      return [];
    }
  },
  /**
   * For updating favorite candidates against job
   */
  [UPDATE_FAVORITE_CANDIDATE]: async (
    { commit }: { commit: Commit },
    payload: UpdateFavoriteCandidatePayload
  ): Promise<any> => {
    try {
      await update_favorite_candidate(payload);
      return true;
    } catch (e) {
      return false;
    }
  },
  /**
   * Action to poke candidate (send email to candidate)
   * @param commit
   * @param {PokeCandidatePayload} payload
   */
  [POKE_CANDIDATE]: async (
    { commit }: { commit: Commit },
    payload: PokeCandidatePayload
  ): Promise<void> => {
    try {
      commit(POKE_CANDIDATE_LOADING, true);
      const response = await poke_candidate(payload);
      commit(ROOT_NOTIFICATION, response.data.message, { root: true });
      commit(POKE_CANDIDATE_LOADING, false);
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      commit(POKE_CANDIDATE_LOADING, false);
    }
  },
  /**
   * Action to save updated job description during posting new job as custom
   * @param {SaveUpdatedJobDescriptionPayload} payload
   */
  [SAVE_UPDATED_JOB_DESCRIPTION]: async (
    { commit }: { commit: Commit },
    payload: SaveUpdatedJobDescriptionPayload
  ): Promise<void> => {
    try {
      commit(RECRUITER_LOADING, true);
      await save_updated_job_description(payload);
      commit(RECRUITER_LOADING, false);
    } catch (e) {
      commit(RECRUITER_LOADING, false);
    }
  },
  [START_CANDIDATE_INTERVIEW]: async (
    { commit }: { commit: Commit },
    payload: StartInterviewPayload
  ): Promise<any> => {
    try {
      const response = await start_interview(payload);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return [];
    }
  },
  [RESUME_CANDIDATE_INTERVIEW]: async (
    { commit }: { commit: Commit },
    payload: ResumeInterviewPayload
  ): Promise<null | string> => {
    try {
      const response = await resume_interview(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [INTERVIEW_LISTING]: async (
    { commit }: { commit: Commit },
    payload: InterviewListingPayload
  ): Promise<null | InterviewListing> => {
    try {
      const response = await fetch_interview_listing(payload);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  [UPDATE_INTERVIEW_STATUS]: async (
    { commit }: { commit: Commit },
    payload: UpdateInterviewStatusPayload
  ): Promise<null | boolean> => {
    try {
      const response = await update_interview_status(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [FETCH_INTERVIEW_HISTORY]: async (
    { commit }: { commit: Commit },
    payload: number
  ): Promise<Interview.InterviewHistoryResponse[]> => {
    try {
      const response = await fetch_interview_history(payload);
      return response.data.data;
    } catch (e) {
      return [];
    }
  },
  [IS_MATCHING_CANDIDATES_EXIST]: async (
    { commit }: { commit: Commit },
    job_id: number
  ): Promise<boolean> => {
    try {
      const response = await is_matching_candidates_exist(job_id);
      return response.data.data.exist;
    } catch (e) {
      return false;
    }
  },
  [ASSESSMENT_LISTING]: async (
    { commit }: { commit: Commit },
    payload: FetchAssessmentListingPayload
  ): Promise<void> => {
    try {
      commit(ASSESSMENT_LISTING_FETCH_LOADING, true);
      const response = await fetch_assessment_listing(payload);
      commit(ASSESSMENT_LISTING_DATA, response.data.data);
      commit(ASSESSMENT_LISTING_FETCH_LOADING, false);
    } catch (e) {
      commit(ASSESSMENT_LISTING_FETCH_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
    }
  },
  [ZAPPYHIRE_JOB_POST]: async (
    { commit }: { commit: Commit },
    payload: ZappyhirePostjobPayload
  ): Promise<ZapphirePostJobResponse | null> => {
    try {
      const response = await zappyhire_post_job(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [ZAPPYHIRE_CREATE_CANDIDATE]: async (
    { commit }: { commit: Commit },
    payload: ZappyhireCreateCandidatePayload
  ): Promise<ZapphireCreateCandidateResponse | null> => {
    try {
      const response = await zappyhire_create_candidate(
        payload.payload,
        payload.job_id,
        payload.candidate_id
      );
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [ZAPPYHIRE_SSO]: async ({
    commit
  }: {
    commit: Commit;
  }): Promise<string | null> => {
    try {
      const response = await zappyhire_sso();
      return response.data.data.token;
    } catch (e) {
      return null;
    }
  },
  [APP_ENG_ASSESSMENT_LISTING]: async (
    { commit }: { commit: Commit },
    payload: AppAssessmentListingPayload
  ): Promise<boolean> => {
    try {
      const response = await fetch_app_assessment_listing(payload);
      const data: AppEngAssessmentListing = {
        limit: payload.limit,
        page: payload.page,
        total: response.data.data.total,
        results: response.data.data.results,
        selected_dates: payload.selected_dates,
        selected_candidates: payload.selected_candidates,
        score_filter: payload.score_filter,
        status_filter: payload.status_filter
      };
      if (payload?.version === "v1") commit(APP_ENG_ASSESSMENT_LISTING, data);
      else commit(APP_ENG_ASSESSMENT_LISTING_V2, data);
      return true;
    } catch (e) {
      return false;
    }
  },
  [APP_ANNOTATION_ASSESSMENT_LISTING]: async (
    { commit }: { commit: Commit },
    payload: AppAssessmentListingPayload
  ): Promise<boolean> => {
    try {
      const response = await fetch_app_assessment_listing(payload);
      const data: AppEngAssessmentListing = {
        limit: payload.limit,
        page: payload.page,
        total: response.data.data.total,
        results: response.data.data.results,
        selected_dates: payload.selected_dates,
        selected_candidates: payload.selected_candidates,
        score_filter: payload.score_filter,
        status_filter: payload.status_filter
      };
      if (payload.version && payload.version === "v1")
        commit(APP_ANNOTATION_ASSESSMENT_LISTING, data);
      else commit(APP_ANNOTATION_ASSESSMENT_LISTING_V2, data);
      return true;
    } catch (e) {
      return false;
    }
  },
  [RESET_ASSESSMENT]: async (
    { commit }: { commit: Commit },
    payload: DeleteAssessmentPayload
  ): Promise<string | null> => {
    try {
      const response = await delete_assessment(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [INCEPTION_STATS]: async ({
    commit
  }: {
    commit: Commit;
  }): Promise<string | null> => {
    try {
      const response = await inception_stats();
      commit(INCEPTION_STATS, response.data.data);
      return null;
    } catch (e) {
      return null;
    }
  },
  [UPDATE_COMPANY_SETTING]: async (
    { commit }: { commit: Commit },
    payload: UpdateCompanySettingPayload
  ): Promise<Company | null> => {
    try {
      const resp = await update_company_setting(payload);
      return resp.data.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  [FETCH_OVERALL_ASSESSMENTS]: async (
    { commit }: { commit: Commit },
    payload: AppOverAllAssessmentListing
  ): Promise<boolean | string> => {
    try {
      const response = await fetch_app_overall_result(payload);

      if (payload.download_report) return response.data.data;

      const data: AppOverAllAssessmentListing = {
        limit: payload.limit,
        page: payload.page,
        total: response.data.data.total,
        selected_dates: payload.selected_dates,
        selected_candidates: payload.selected_candidates,
        score_filter: payload.score_filter,
        results: response.data.data.results,
        average_score_filter: payload.average_score_filter,
        assessment_version: payload.assessment_version,
        english_assessment_filter: payload.english_assessment_filter,
        annotation_assessment_filter: payload.annotation_assessment_filter,
        eid_name_filter: payload.eid_name_filter
      };
      commit(FETCH_OVERALL_ASSESSMENTS, data);
      return true;
    } catch (e) {
      return false;
    }
  }
};

const result = {
  overall_result: {
    coherence: 2.67,
    "lexical-resource": 4.83,
    grammar: 1.83,
    pronounciation: 2.17
  },
  assessment: {
    id: 1,
    assessment_name: "English Speaking",
    assessment_type: "english_speak_v1",
    created_at: null
  },
  details: [
    {
      assessment_result: {
        coherence: 2,
        "lexical-resource": 2,
        grammar: 2
      },
      question: {
        question_id: 1,
        assessment_id: 1,
        question_type: "text",
        question_text: "Can you tell me your full name, please?",
        created_by: "None",
        created_at: "2023-09-15 15:39:38.355000",
        updated_at: "None",
        is_question_enable: true,
        question_time_allowed: 90,
        question_options: {
          question_voice_url:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/assessments/english_speak_v1/1_1694771184065.mp3"
        }
      },
      answer: {
        answer_id: 4,
        user_id: 542,
        question_id: 1,
        answer_type: "text",
        answer: "my name is Faiz",
        answer_url: "None",
        created_at: "2023-09-19 17:07:07.496",
        updated_at: "None",
        assessment_id: 1
      }
    },
    {
      assessment_result: {
        coherence: 1,
        "lexical-resource": 2,
        grammar: 2
      },
      question: {
        question_id: 2,
        assessment_id: 1,
        question_type: "text",
        question_text:
          "What is your hometown or where are you currently living?",
        created_by: "None",
        created_at: "2023-09-15 15:37:38.355000",
        updated_at: "None",
        is_question_enable: true,
        question_time_allowed: 90,
        question_options: {
          question_voice_url:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/assessments/english_speak_v1/2_1694772325486.mp3"
        }
      },
      answer: {
        answer_id: 5,
        user_id: 542,
        question_id: 2,
        answer_type: "text",
        answer: "I don't know",
        answer_url: "None",
        created_at: "2023-09-19 17:07:14.307",
        updated_at: "None",
        assessment_id: 1
      }
    },
    {
      assessment_result: {
        coherence: 2,
        "lexical-resource": 2,
        grammar: 2
      },
      question: {
        question_id: 3,
        assessment_id: 1,
        question_type: "text",
        question_text:
          "Do you enjoy living in your current city? Why or why not?",
        created_by: "None",
        created_at: "2023-09-15 10:03:17.362464",
        updated_at: "None",
        is_question_enable: true,
        question_time_allowed: 90,
        question_options: {
          question_voice_url:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/assessments/english_speak_v1/3_1694772461694.mp3"
        }
      },
      answer: {
        answer_id: 6,
        user_id: 542,
        question_id: 3,
        answer_type: "text",
        answer: "yes yes",
        answer_url: "None",
        created_at: "2023-09-19 17:07:26.643",
        updated_at: "None",
        assessment_id: 1
      }
    },
    {
      assessment_result: {
        coherence: 2,
        "lexical-resource": 2,
        grammar: 2
      },
      question: {
        question_id: 4,
        assessment_id: 1,
        question_type: "text",
        question_text: "What do you like to do in your free time?",
        created_by: "None",
        created_at: "2023-09-15 10:03:49.278246",
        updated_at: "None",
        is_question_enable: true,
        question_time_allowed: 90,
        question_options: {
          question_voice_url:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/assessments/english_speak_v1/4_1694772487326.mp3"
        }
      },
      answer: {
        answer_id: 7,
        user_id: 542,
        question_id: 4,
        answer_type: "text",
        answer: "I will not tell you",
        answer_url: "None",
        created_at: "2023-09-19 17:07:34.301",
        updated_at: "None",
        assessment_id: 1
      }
    },
    {
      assessment_result: {
        coherence: 2,
        "lexical-resource": 2,
        grammar: 2
      },
      question: {
        question_id: 5,
        assessment_id: 1,
        question_type: "text",
        question_text: "How do you usually spend your weekends?",
        created_by: "None",
        created_at: "2023-09-15 10:04:23.040158",
        updated_at: "None",
        is_question_enable: true,
        question_time_allowed: 90,
        question_options: {
          question_voice_url:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/assessments/english_speak_v1/5_1694772506029.mp3"
        }
      },
      answer: {
        answer_id: 8,
        user_id: 542,
        question_id: 5,
        answer_type: "text",
        answer: "will think about it",
        answer_url: "None",
        created_at: "2023-09-19 17:07:42.398",
        updated_at: "None",
        assessment_id: 1
      }
    },
    {
      assessment_result: {
        coherence: 1,
        "lexical-resource": 1,
        grammar: 1
      },
      question: {
        question_id: 6,
        assessment_id: 1,
        question_type: "long_text",
        question_text:
          "Describe a memorable journey you have taken. Please include details like the destination, the purpose of the journey, and what made it memorable.",
        created_by: "None",
        created_at: "2023-09-15 10:33:12.975352",
        updated_at: "None",
        is_question_enable: true,
        question_time_allowed: 240,
        question_options: {
          question_voice_url:
            "https://staging-hcms-textract.s3.eu-west-2.amazonaws.com/open/assessments/english_speak_v1/6_1694774006155.mp3"
        }
      },
      answer: {
        answer_id: 9,
        user_id: 542,
        question_id: 6,
        answer_type: "long_text",
        answer: "will think about it",
        answer_url: "None",
        created_at: "2023-09-19 17:07:55.390",
        updated_at: "None",
        assessment_id: 1
      }
    }
  ],
  english_writing: [
    {
      title: "Task #1",
      value_text: "3/5",
      value: 65,
      color: "green",
      response:
        "Bursting with imagery, motion, interaction and distraction though it is, today’s World Wide Web is still primarily a conduit for textual information. In HTML5, the focus on writing and authorship is more pronounced than ever. It’s evident in the very way that new elements such as article and aside are named. HTML5 asks us to treat the HTML document more as… well, a document.It’s not just the specifications that are changing, either. Much has been made of permutations to Google’s algorithms, which are beginning to favor better written, more authoritative content (and making work for the growing content strategy industry). Google’s bots are now charged with asking questions like, “Was the article edited well, or does it appear sloppy or hastily produced?” and “Does this article provide a complete or comprehensive description of the topic?,” the sorts of questions one might expect to be posed by an earnest college professor"
    },
    {
      title: "Task #2",
      color: "green",
      value_text: "4/5",
      value: 85,
      response:
        "Bursting with imagery, motion, interaction and distraction though it is, today’s World Wide Web is still primarily a conduit for textual information. In HTML5, the focus on writing and authorship is more pronounced than ever. It’s evident in the very way that new elements such as article and aside are named. HTML5 asks us to treat the HTML document more as… well, a document.It’s not just the specifications that are changing, either. Much has been made of permutations to Google’s algorithms, which are beginning to favor better written, more authoritative content (and making work for the growing content strategy industry). Google’s bots are now charged with asking questions like, “Was the article edited well, or does it appear sloppy or hastily produced?” and “Does this article provide a complete or comprehensive description of the topic?,” the sorts of questions one might expect to be posed by an earnest college professor Bursting with imagery, motion, interaction and distraction though it is, today’s World Wide Web is still primarily a conduit for textual information. In HTML5, the focus on writing and authorship is more pronounced than ever. It’s evident in the very way that new elements such as article and aside are named. HTML5 asks us to treat the HTML document more as… well, a document.It’s not just the specifications that are changing, either. Much has been made of permutations to Google’s algorithms, which are beginning to favor better written, more authoritative content (and making work for the growing content strategy industry). Google’s bots are now charged with asking questions like, “Was the article edited well, or does it appear sloppy or hastily produced?” and “Does this article provide a complete or comprehensive description of the topic?,” the sorts of questions one might expect to be posed by an earnest college professor"
    }
  ]
};
