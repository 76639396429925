export enum ResumeUploadsStatus {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed"
}

export enum EmailInvites {
  RESEND_EMAIL = "resend-email",
  ONBOARDING_INVITE = "onboarding-invite"
}

export enum AppLanguages {
  ENGLISH = "en",
  ARABIC = "ar"
}

export enum AssessmentRecordType {
  SCREEN = "screen",
  CAMERA = "camera"
}

export enum SocketEvents {
  MEDIA_RECORDER_RECORDING = "media-recorder-recording",
  UPLOAD_SCREEN_SHOTS = "upload-screen-shots",

  CANCELLED_ASSESSMENT = "cancelled-assessment"
}
