import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { SearchedCandidateProfile } from "@/store/modules/recruiter/interfaces";
import jwtDecode, { JwtPayload } from "jwt-decode";
import moment from "moment/moment";
import { get_color_by_name } from "./colors";

export function clean_string(value: string): string {
  if (value) return value.trim().toLowerCase();
  return "";
}

/**
 * Return Regex for email validation
 * @return Regex for email validation. You can use .test function to validate string
 */
function get_email_validator_regex(): RegExp {
  return /^(([^<>()#$%&*{}~=[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
}

export function get_password_validator_regex(): RegExp {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@~`$!#^()_\-=+%*?&])[A-Za-z\d@~`$!#^()_\-=+%*?&]{3,16}$/;
}

/**
 * Return email rules for input filed
 * Rules are: Email not empty, email is valid again email validator regex
 * @return Array of validations, use with v-text-field => rules prop
 */
export function get_email_rules(): { (value: string): boolean | string }[] {
  return [
    (value: string) => !!value || "Email is required",
    (value: string) =>
      get_email_validator_regex().test(value) || "Invalid Email"
  ];
}

/**
 * Return password rules for input filed
 * Rules are: Password not empty, Password contain 1 lower case, 1 upper case, 1 letter & 1 number must and length between 8-16
 * @return Array of validations, use with v-text-field => rules prop
 */
export function get_password_rules(): { (value: string): boolean | string }[] {
  return [
    (value: string) => !!value || "Password is required",
    (value: string) =>
      (value && value.length > 8) ||
      "Password must contain at least 8 characters",
    (value: string) =>
      (value && value.length < 16) ||
      "Password length should be less than 16 characters"
  ];
}

/**
 * Return Regex for facebook URL validation
 * @return Regex for facebook URl validation. You can use .test function to validate string
 */
export function get_facebook_regex(): RegExp {
  return /^(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;
}

/**
 * Return Regex for instagram URL validation
 * @return Regex for instagram URl validation. You can use .test function to validate string
 */
export function get_instagram_regex(): RegExp {
  return /^(?:https?:\/\/)?(?:www\.)?(m\.instagram|instagram)\.(com)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;
}

/**
 * Return Regex for twitter URL validation
 * @return Regex for twitter URl validation. You can use .test function to validate string
 */
export function get_twitter_regex(): RegExp {
  return /^(?:https?:\/\/)?(?:www\.)?(twitter)\.(com)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;
}

/**
 * Return Regex for linkedin URL validation
 * @return Regex for linkedin URl validation. You can use .test function to validate string
 */
export function get_linkedin_regex(): RegExp {
  return /^(?:https?:\/\/)?(?:www\.)?(linkedin)\.(com)\/(in|pub|public-profile\/in|public-profile\/pub|company|jobs)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;
}

/**
 * Format overall score. Return value will between 0 - 1
 * @param score: number
 */
export function format_overall_score(score: string): number {
  return parseInt((parseFloat(score) * 100).toFixed(0));
}

export function format_assessment_score(score: number): number {
  return parseInt((score * 100).toFixed(0));
}

export function update_assessments_scores(
  score: number,
  divider: number,
  total: number
): number {
  return (score / divider) * total;
}
/**
 * Return initials of name
 * @param name
 */
export function get_name_initials(name: string): string {
  const parts = name.split(" ");
  let initials = "";
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== "") {
      initials += parts[i][0];
    }
  }
  return initials;
}

/**
 * Validate file type
 * @param file_type => file type to check
 * @param file => File to check
 * @return boolean
 */
export function validate_file_type(file_type: string, file: File): boolean {
  const _file_type = clean_string(file_type);
  let valid: boolean;
  switch (_file_type) {
    case "pdf": {
      valid = file.type === "application/pdf";
      break;
    }
    case "text": {
      valid = file.type === "text/plain";
      break;
    }
    case "word": {
      valid =
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/doc" ||
        file.type === "application/ms-doc" ||
        file.type === "application/msword";
      break;
    }
    case "img": {
      valid =
        file.type === "image/svg+xml" ||
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      break;
    }
    default: {
      valid = false;
    }
  }
  return valid;
}

/**
 * Convert file size(bytes) to MBs
 * @param size: bytes
 * @return string => size in MBs
 */
export function get_file_size(size: number): string {
  return (size / (1024 * 1024)).toFixed(2);
}

/**
 * Function to capitalize sentence each word
 * @param text => sentence to capitalize
 */
export function capitalize_text(text: string): string {
  return text.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
}

/**
 * Generate random key length => 5
 */
export function generate_random_key(): number {
  return Math.floor(Math.random() * 99999);
}

/**
 * Function to return random number between 2 numbers
 * @param min
 * @param max
 */
export function get_random_number_between(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Function to wait
 * @param time: time ikn seconds
 */
export async function wait_until(time = 3000): Promise<void> {
  return await new Promise((resolve) => setTimeout(resolve, time));
}

/**
 * Parse str into int
 * @param {string} value => string value to convert in int
 * @return number => if parsed successfully
 * @return null => if parsed failed
 */
export function parseStrToInt(value: string): number | null {
  const result = parseInt(value);
  if (isNaN(result)) return null;
  else return result;
}

export async function download_file(url: string): Promise<void> {
  const a = document.createElement("a");
  a.download = url;
  a.href = url;
  a.style.display = "hidden";
  document.body.appendChild(a);
  a.click();
  await wait_until(1000);
  document.body.removeChild(a);
}

export function format_date(date: string): string {
  return moment(date).format("LL");
}

export function is_access_token_expired(token: string): boolean {
  if (!token) return true;
  const decoded_token = jwtDecode<JwtPayload>(token);
  const current_time = Date.now() / 1000;
  return decoded_token.exp ? decoded_token.exp < current_time : true;
}

export interface FetchCandidateDetailsReturn {
  designation: string;
  name: string;
  score: number;
  name_initials: string;
}

export function fetch_candidate_details(
  profile: SearchedCandidateProfile,
  score: number
): FetchCandidateDetailsReturn {
  let designation = "";
  let name = "";
  let _score = 0;
  let name_initials = "";
  if (
    profile.work_history_detailed &&
    Object.values(profile.work_history_detailed).length > 0
  )
    designation = Object.values(profile.work_history_detailed)[0].job_title;

  if (
    profile.personal_details &&
    Object.values(profile.personal_details).length > 0
  )
    name = Object.values(profile.personal_details.name).join(" ");
  _score = format_overall_score(score?.toString() ?? "0");
  name_initials = get_name_initials(name);
  return { designation, name, score: _score, name_initials };
}

// Convert ms to mins using moment
export function convert_ms_to_minutes(ms: number): number {
  return moment.duration(ms).minutes();
}

// Convert seconds to minutes using moment
export function convert_seconds_to_minutes(seconds: number): number {
  return moment.duration(seconds, "seconds").asMinutes();
}

export function format_seconds_to_minutes(seconds: number): string {
  const duration = moment.duration(seconds, "seconds");
  return moment.utc(duration.asMilliseconds()).format("mm:ss");
}

/**
 * Return Assessment Status in readable format
 * @param status { AppAssessments.AppAssessmentStatus} => status to convert
 * @returns string => readable status
 */
export function get_app_assessment_status(
  status: AppAssessments.AppAssessmentStatus
): string {
  const _status = clean_string(status);
  if (_status === clean_string(AppAssessments.AppAssessmentStatus.CANCELLED))
    return "Cancelled";
  else if (
    _status === clean_string(AppAssessments.AppAssessmentStatus.COMPLETED)
  )
    return "Completed";
  else if (
    _status ===
    clean_string(AppAssessments.AppAssessmentStatus.RESULT_PROCESSING)
  )
    return "Result Processing";
  else if (
    _status === clean_string(AppAssessments.AppAssessmentStatus.IN_PROGRESS)
  )
    return "In Progress";
  else if (_status === clean_string(AppAssessments.AppAssessmentStatus.FAILED))
    return "Failed";
  else return "N/A";
}

/**
 * Return Assessment Status color
 * @param status { AppAssessments.AppAssessmentStatus} => status to get color
 * @returns string => color
 */
export function get_app_assessment_status_color(
  status: AppAssessments.AppAssessmentStatus
): string {
  const _status = clean_string(status);
  if (_status === clean_string(AppAssessments.AppAssessmentStatus.CANCELLED))
    return "invite-sent-status";
  else if (
    _status === clean_string(AppAssessments.AppAssessmentStatus.COMPLETED)
  )
    return "finished-status";
  else if (
    _status ===
      clean_string(AppAssessments.AppAssessmentStatus.RESULT_PROCESSING) ||
    _status === clean_string(AppAssessments.AppAssessmentStatus.IN_PROGRESS)
  ) {
    return "in-process-status";
  } else if (
    _status === clean_string(AppAssessments.AppAssessmentStatus.FAILED)
  )
    return "failed-status";
  else return "finished-status";
}

export function get_color_for_percentage(percentage: number): string {
  if (percentage < 50) return "danger--text";
  else if (percentage >= 50 && percentage <= 70) return "warning--text";
  else return "success--text";
}

export function get_pass_or_fail_class(percentage: number): string {
  if (percentage < 50) return "failed-status";
  else return "finished-status";
}

export function assessment_pass_fail_result(score: number): boolean {
  return score >= 50;
}
