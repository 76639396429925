export const COMMON_LOADING = "COMMON_LOADING";

export const GET_UNIQUE_SECTORS = "GET_UNIQUE_SECTORS";

export const SET_UNIQUE_SECTORS = "SET_UNIQUE_SECTORS";

export const ROLES = "ROLES";

export const GET_ROLE_BY_NAME = "GET_ROLE_BY_NAME";

export const GET_JOB_BY_ID = "GET_JOB_BY_ID";

export const FETCH_SKILLS_GROUP = "FETCH_SKILLS_GROUP";

export const JOB_SEARCH = "JOB_SEARCH";

// For maintaining different states for job viewing
export const VIEW_JOB_STATE = "VIEW_JOB_STATE";

// For initializing startup data
export const INIT_COMMON_DATA = "INIT_COMMON_DATA";

// For skills search
export const SKILL_SEARCH = "SKILL_SEARCH";

// For reset common state
export const RESET_COMMON_STATE = "RESET_COMMON_STATE";

export const GET_USER_WITH_PROFILE = "GET_USER_WITH_PROFILE";

export const INIT_NOTIFICATIONS = "INIT_NOTIFICATIONS";

export const NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING";
export const NOTIFICATIONS_COUNT = "NOTIFICATIONS_COUNT";

export const NOTIFICATIONS = "NOTIFICATIONS";

export const NOTIFICATION_ACTION = "NOTIFICATION_ACTION";

export const NOTIFICATION_ACTION_LOADING = "NOTIFICATION_ACTION_LOADING";

export const GET_ASSESSMENT_SUMMARY = "GET_ASSESSMENT_SUMMARY";
export const GET_ASSESSMENT_PDF = "GET_ASSESSMENT_PDF";

export const SITE_DIRECTION = "SITE_DIRECTION";

export const GET_ASSESSMENT = "GET_ASSESSMENT";

export const GET_USER_INFO = "GET_USER_INFO";

export const USER_INFO = "USER_INFO";

export const SELECTED_USERS = "SELECTED_USERS";

export const RESET_USERS = "RESET_USERS";

export const APP_ASSESSMENTS = "APP_ASSESSMENTS";

export const APP_ASSESSMENT_RESULT = "APP_ASSESSMENT_RESULT";

export const APP_ASSESSMENT_RESULT_PDF = "APP_ASSESSMENT_RESULT_PDF";

export const SEND_EMAIL = "SEND_EMAIL";

export const UPLOAD_FILE_CHUNK = "UPLOAD_FILE_CHUNK";

export const USER_WITH_EID_AND_NAME = "USER_WITH_EID_AND_NAME";

export const USER_INFO_BY_EID_NAME = "USER_INFO_BY_EID_NAME";

export const SELECTED_USER_BY_EID_NAME = "SELECTED_USER_BY_EID_NAME";

export const RESER_USER_EID_NAME = "RESER_USER_EID_NAME";
