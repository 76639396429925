export const RECRUITER_LOADING = "RECRUITER_LOADING";

export const POST_JOB = "POST_JOB";

// Job listing with candidates
export const JOBS_POSTED_BY_RECRUITER_LOADING =
  "JOBS_POSTED_BY_RECRUITER_LOADING";

export const JOBS_POSTED_BY_RECRUITER = "JOBS_POSTED_BY_RECRUITER";

export const MATCHING_CANDIDATES = "MATCHING_CANDIDATES";

export const UPDATE_MATCHING_CANDIDATES = "UPDATE_MATCHING_CANDIDATES";

export const TOP_JOBS_WITH_CANDIDATES = "TOP_JOBS_WITH_CANDIDATES";
export const REMOVE_TOP_JOBS_WITH_CANDIDATES =
  "REMOVE_TOP_JOBS_WITH_CANDIDATES";

export const UPDATE_TOP_MATCHING_CANDIDATES = "UPDATE_TOP_MATCHING_CANDIDATES";

export const DELETE_JOB_SOFTLY = "DELETE_JOB_SOFTLY";

export const VIEW_CANDIDATES_DYNAMICALLY = "VIEW_CANDIDATES_DYNAMICALLY";

// For fetching matching candidates/applicants
export const FETCH_MATCHING_CANDIDATES = "FETCH_MATCHING_CANDIDATES";

// For autocomplete job searching
export const AUTOCOMPLETE_JOB_SEARCH = "AUTOCOMPLETE_JOB_SEARCH";

// For initialize recruiter
export const INIT_RECRUITER = "INIT_RECRUITER";

// For add new job in top job data set and removing last job
export const PUSH_TOP_JOB_WITH_CANDIDATES = "PUSH_TOP_JOB_WITH_CANDIDATES";

// For activating published job
export const ACTIVATE_PUBLISHED_JOB = "ACTIVATE_PUBLISHED_JOB";

// For setting & Getting recommend job profiles
export const RECOMMEND_JOB_PROFILES = "RECOMMEND_JOB_PROFILES";

export const ALL_JOBS_COUNT = "DASHBOARD_NUMBERS";

// For update job
export const UPDATE_JOB = "UPDATE_JOB";

// For reset recruiter state
export const RESET_RECRUITER_STATE = "RESET_RECRUITER_STATE";

// When recruiter want to view matching candidate this payload will contain job_id & cv_id
// Job id and cv id is required to view candidate profile
export const CANDIDATE_VIEW_PAYLOAD = "CANDIDATE_VIEW_PAYLOAD";

export const MEMBERS_JOBS_STATISTICS = "MEMBERS_JOBS_STATISTICS";

// For get favorite candidate against job
export const GET_FAVORITE_CANDIDATES = "GET_FAVORITE_CANDIDATES";
// For update favorite candidate against job
export const UPDATE_FAVORITE_CANDIDATE = "UPDATE_FAVORITE_CANDIDATE";

// For containing all candidates
export const ALL_CANDIDATES = "ALL_CANDIDATES";

// For poke candidate
export const POKE_CANDIDATE = "POKE_CANDIDATE";
export const POKE_CANDIDATE_LOADING = "POKE_CANDIDATE_LOADING";

// For saving updated job description
export const SAVE_UPDATED_JOB_DESCRIPTION = "SAVE_UPDATED_JOB_DESCRIPTION";

export const START_CANDIDATE_INTERVIEW = "START_CANDIDATE_INTERVIEW";
export const RESUME_CANDIDATE_INTERVIEW = "RESUME_CANDIDATE_INTERVIEW";
export const POST_JOB_LIMIT_ERROR = "POST_JOB_LIMIT_ERROR";

// For holding standardised job to add
export const HOLD_STANDARDISED_JOB = "HOLD_STANDARDISED_JOB";

// For interviews

// => for autocompleted searched job
export const INTERVIEW_SEARCHED_JOB = "INTERVIEW_SEARCHED_JOB";

// => For fetching all interviews
export const INTERVIEW_LISTING = "INTERVIEW_LISTING";

export const UPDATE_INTERVIEW_STATUS = "UPDATE_INTERVIEW_STATUS";

export const FETCH_INTERVIEW_HISTORY = "FETCH_INTERVIEW_HISTORY";

export const RECRUITER_INTERVIEW_SEARCH_JOB_LOADING =
  "RECRUITER_INTERVIEW_SEARCH_JOB_LOADING";

export const VIEW_CANDIDATES_PAGINATION = "VIEW_CANDIDATES_PAGINATION";

export const SEARCHED_SKILL_FILTER = "SEARCHED_SKILL_FILTER";

export const INTERVIEW_OBJECT = "INTERVIEW_OBJECT";
export const UPDATE_INTERVIEW_PAGINATION = "UPDATE_INTERVIEW_PAGINATION";
export const IS_MATCHING_CANDIDATES_EXIST = "IS_MATCHING_CANDIDATES_EXIST";

// For holding that either user edit standardised job or not during job posting
export const EDITED_STANDARDISED_JOB = "EDITED_STANDARDISED_JOB";

export const ASSESSMENT_LISTING = "ASSESSMENT_LISTING";

export const ASSESSMENT_LISTING_FETCH_LOADING =
  "ASSESSMENT_LISTING_FETCH_LOADING";

export const ASSESSMENT_LISTING_DATA = "ASSESSMENT_LISTING_DATA";

export const ASSESSMENT_LISTING_PAGINATION = "ASSESSMENT_LISTING_PAGINATION";

export const ASSESSMENT_LISTING_DATES_FILTER =
  "ASSESSMENT_LISTING_DATES_FILTER ";

export const DELETE_ASSESSMENT_LISTING_DATES_FILTER =
  "DELETE_ASSESSMENT_LISTING_DATES_FILTER";

export const SELECTED_CANDIDATE_INTERVIEW_REPORT =
  "SELECTED_CANDIDATE_INTERVIEW_REPORT";
export const JOB_LISTING_PAGINATION = "JOB_LISTING_PAGINATION";

export const JOB_LISTING_DATE_FILTER = "JOB_LISTING_DATE_FILTER";

export const JOB_LISTING_SEARCHED_JOB_FILTER =
  "JOB_LISTING_SEARCHED_JOB_FILTER";

export const ZAPPYHIRE_JOB_POST = "ZAPPYHIRE_JOB_POST";

export const ZAPPYHIRE_CREATE_CANDIDATE = "ZAPPYHIRE_CREATE_CANDIDATE";

export const ZAPPYHIRE_SSO = "ZAPPYHIRE_SSO";

export const UPDARTE_INTERVIEW_LISTING_OBJECT =
  "UPDARTE_INTERVIEW_LISTING_OBJECT";

export const GET_INTERVIEW_LISTING_OBJECT = "GET_INTERVIEW_LISTING_OBJECT";

export const APP_ENG_ASSESSMENT_LISTING = "APP_ENG_ASSESSMENT_LISTING";
export const APP_ENG_ASSESSMENT_LISTING_V2 = "APP_ENG_ASSESSMENT_LISTING_V2";

export const APP_ANNOTATION_ASSESSMENT_LISTING =
  "APP_ANNOTATION_ASSESSMENT_LISTING";
export const APP_ANNOTATION_ASSESSMENT_LISTING_V2 =
  "APP_ANNOTATION_ASSESSMENT_LISTING_V2";

export const ACTIVE_ASSESSMENT = "ACTIVE_ASSESSMENT";

export const RESET_ASSESSMENT = "RESET_ASSESSMENT";

export const INCEPTION_STATS = "INCEPTION_STATS";

export const UPDATE_COMPANY_SETTING = "UPDATE_COMPANY_SETTING";

export const FETCH_OVERALL_ASSESSMENTS = "FETCH_OVERALL_ASSESSMENTS";
