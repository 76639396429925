import { RouteConfig } from "vue-router";
import { recruiter_jobs } from "@/router/sub_routes/recruiter/jobs";
import { recruiter_candidates } from "@/router/sub_routes/recruiter/candidates";
import { recruiter_employees } from "@/router/sub_routes/recruiter/employees";
import { UserState } from "@/store/modules/auth/interfaces";
import { recruiter_interview_routes } from "@/router/sub_routes/recruiter/interview_routes";
import { recruiter_assessment_routes } from "@/router/sub_routes/recruiter/recruiter_assessment_routes";
import { recruiter_inception_assessment_routes } from "./sub_routes/recruiter/recruiter_inception_assessments_routes";

export const recruiter_routes: RouteConfig = {
  path: "/recruiter",
  component: () => import("@/views/recruiter/RecruiterWrapper.vue"),
  meta: {
    user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
  },
  children: [
    {
      path: "",
      redirect: {
        name: "recruiter-dashboard"
      },
      meta: {
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    },
    {
      path: "dashboard",
      name: "recruiter-dashboard",
      component: () =>
        import("@/views/recruiter/InceptionRecruiterDashboard.vue"),
      meta: {
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    },
    // recruiter_jobs,
    recruiter_candidates,
    recruiter_employees,
    // recruiter_interview_routes,
    recruiter_inception_assessment_routes,
    // recruiter_assessment_routes,
    {
      path: "skills-analytics",
      name: "skills-analytics",
      component: () => import("@/views/recruiter/SkillsAnalytics.vue"),
      meta: {
        user_state: []
      }
    },
    {
      path: "admin",
      name: "admin",
      component: () => import("@/views/recruiter/admin/AdminIndex.vue"),
      meta: {
        user_state: [UserState.RECRUITER_ADMIN]
      },
      children: [
        {
          path: "candidates",
          name: "all-candidates",
          component: () => import("@/views/recruiter/admin/AllCandidates.vue"),
          meta: {
            user_state: [UserState.RECRUITER_ADMIN]
          }
        },
        {
          path: "view",
          name: "view-candidate",
          component: () =>
            import("@/views/recruiter/admin/CandidateProfile.vue"),
          meta: {
            user_state: [UserState.RECRUITER_ADMIN]
          }
        }
      ]
    },
    {
      path: "error",
      name: "recruiter-error",
      component: () => import("@/views/ErrorPage.vue"),
      meta: {
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    },
    {
      path: "*",
      component: () => import("../views/NotFound.vue"),
      meta: {
        user_state: [
          UserState.UNAUTHENTICATED,
          UserState.EMAIL_VERIFICATION_PENDING,
          UserState.EMAIL_VERIFICATION_COMPLETED,
          UserState.ONBOARDING_PENDING,
          UserState.RECRUITER,
          UserState.RECRUITER_ADMIN,
          UserState.CANDIDATE
        ]
      }
    }
  ]
};
