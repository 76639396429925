import { candidate_base_url, recruiter_base_url } from "@/store/urls/base_urls";

export const GET_MATCHING_JOBS = candidate_base_url + "matching-jobs";
export const UPSKILL_COURSES = candidate_base_url + "upskill-courses/";
export const SKILL_GAP_AGAINST_JOB = candidate_base_url + "skill-gap/";
export const SEARCH_WHOLE_SYSTEM_JOBS = candidate_base_url + "jobs/";

// This should be in candidate routes but backend implemented in recruiter module -_-
export const FETCH_CAREER_PATH = candidate_base_url + "career-path/";
// For liking job/disliking job
export const TOGGLE_JOB_FAV_STATUS = candidate_base_url + "job/";

// For getting fav jobs
export const FETCH_FAV_JOBS = candidate_base_url + "favorite-jobs/";

// For registration tenant
export const REGISTER_TENANT = candidate_base_url + "register-tp/";
// For checking tenant existence
export const CHECK_TENANT_EXISTENCE = candidate_base_url + "principles/exist/";

export const FETCH_ASSESSMENT_QUESTIONS =
  candidate_base_url + "principles/assessments/questions/";

export const SUBMIT_ASSESSMENT_ANSWERS =
  candidate_base_url + "principles/assessments/answers/";

export const SUBMIT_APP_ASSESSMENT_ANSWERS =
  recruiter_base_url + "assessments/";

export const SUBMIT_APP_ASSESSMENT_MEDIA = recruiter_base_url + "assessments/";
