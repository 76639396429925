import { AppAssessmentNames } from "@/store/modules/common/interfaces";

export namespace AppAssessments {
  export interface Assessment {
    assessment_name: string;
    assessment_type: AppAssessmentNames;
    created_at: string;
    status?: string;
    allowed_time: number | null;
    id: number;
  }

  export interface AssessmentQuestionnaires {
    id: number;
    assessment_id: number;
    question_type: QuestionType;
    question_text: Text;
    created_by: number | null;
    created_at: string;
    updated_at: string | null;
    is_question_enable: boolean;
    question_time_allowed: number | null; // Seconds
    question_options: QuestionOptions | null;
  }

  export interface AssessmentAnswers {
    id: number;
    user_id: number;
    question_id: number;
    answer_type: AnswerType;
    answer: Text;
    answer_url?: Text;
    created_at?: Date;
    updated_at?: Date;
  }

  export interface QuestionOptions {
    question_voice_url?: string;
    data_table?: {
      columns: string[];
      rows: DataTableRows[];
    };
    question_material?: string;
    allowed_characters: number[];
    ref?: AnnotationAssessmentRefs;
    title?: string;
    responses?: QuestionResponses[];
    filter?: QuestionFilter;
    table?: {
      columns: string[];
      rows: {
        title: string;
        col1: string;
        col2: string;
        col3: string;
      };
    };
    exclude?: boolean;
    order: number;
  }

  export enum QuestionFilter {
    SPEAKING = "speaking",
    WRITTEN = "written"
  }
  export interface DataTableRows {
    title: string;
    values: string[];
  }
  export enum QuestionType {
    TEXT = "text",
    JSON = "json",
    LONG_TEXT = "long_text",
    HTML = "html"
  }
  export enum AnswerType {
    TEXT = "text",
    JSON = "json",
    LONG_TEXT = "long_text",
    HTML = "html"
  }
  export enum AnnotationAssessmentRefs {
    PART1 = "part1",
    PART2 = "part2",
    PART3 = "part3",
    PART4 = "part4",
    PART5 = "part5"
  }

  export interface QuestionResponses {
    content?: string;
    options?: QuestionResponsesOptions[];
    arabic?: string;
    title?: string;
    ans?: string;
  }
  export interface QuestionResponsesOptions {
    option1?: string;
    option2?: string;
    ans?: string;
  }
  export enum AppAssessmentStatus {
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED",
    RESULT_PROCESSING = "RESULT_PROCESSING",
    FAILED = "FAILED",
    IN_PROGRESS = "IN_PROGRESS"
  }

  export interface AssessmentResults {
    id: number;
    assessment: Assessment;
    user_id: number;
    user: AssessmentUserObject;
    created_at: string;
    updated_at: string | null;
    total_questions: number | null;
    current_question: number | null;
    status: AppAssessmentStatus | null;
    result: AssessmentResult | null;
    urls: AssessmentResultUrls | null;
  }
  export interface AssessmentResultUrls {
    screen_shots?: string[];
    camera_recording?: string;
    screen_recording?: string;
    report?: string;
  }
  export interface AssessmentResult {
    overall_result: OverallResult;
    assessment: Assessment;
    details: AssessmentResultDetails[];
    english_speaking_score: number;
    english_writing_score: number;
    overall_score: number;
  }

  export interface OverallResult {
    coherence: number;
    lexical_resource: number;
    grammar: number;
  }

  export enum OverallResultKeys {
    COHERENCE = "coherence",
    LEXICAL_RESOURCE = "lexical_resource",
    GRAMMAR = "grammar"
  }
  export interface AssessmentResultDetails {
    assessment_result: OverallResult;
    question: AssessmentQuestionnaires;
    answer: AssessmentAnswers;
    selected?: boolean;
  }
  export interface AnnotationAssessmentResults {
    id: number;
    assessment: Assessment;
    user_id: number;
    user: AssessmentUserObject;
    created_at: string;
    updated_at: string | null;
    total_questions: number | null;
    current_question: number | null;
    status: AppAssessmentStatus | null;
    result: AnnotationAssessmentResult | null;
    urls: AssessmentResultUrls | null;
  }
  export interface AnnotationAssessmentResult {
    part1: {
      details: [];
      score: number;
      total_question: number;
      heading: string;
    };
    part2: {
      details: [];
      score: number;
      total_question: number;
      heading: string;
    };
    part3: {
      score: number;
      total_question: number;
      heading: string;
      details: {
        question_number: number;
        rating: number;
      }[];
    };
    part4: {
      details: {
        answer: string;
        title: string;
        content: string;
      }[];
      score: number;
      assessment_result?: OverallResult;
      total_question: number;
      heading: string;
    };
    part5: {
      details: {
        question: string;
        answer: string;
      }[];
      score: number;
      assessment_result: OverallResult;
      total_question: number;
      heading: string;
    };
    status: string;
  }
  export interface AssessmentUserObject {
    id: number;
    last_name: string;
    first_name: string;
    avatar_uri: string;
  }
  export interface CompanySetting {
    candidates_english: string;
    candidates_annotation: string;
    admin_english: string;
    admin_annotation: string;
  }
  export enum CurrentAssessmentType {
    CANDIDATE_ENGLISH = "candidates_english",
    CANDIDATE_ANNOTATION = "candidates_annotation",
    ADMIN_ENGLISH = "admin_english",
    ADMIN_ANNOTATION = "admin_annotation"
  }
  export interface AiEvaluationData {
    title: string;
    score: number;
  }
}
