import { AppAssessments } from "../data_objects/app_assessments";

export enum ViewAssessmentsOptions {
  SPEAKING = "SPEAKING",
  WRITING = "WRITING"
}

export enum CandidateAssessmentsViewOptions {
  speaking,
  writing
}

export interface AppAnnotation2Answers {
  content: string;
  options: AppAnnotation2Options[];
}

export interface AppAnnotation2Options
  extends AppAssessments.QuestionResponsesOptions {
  op1_selected: boolean;
  op2_selected: boolean;
}

export interface AppAnnotation3Answers {
  content: string;
  arabic: string;
  answer: string;
}

export interface AppAnnotation4Answers {
  content: string;
  title: string;
  answer: string;
}

export interface AppAnnotation5Answers {
  question: string;
  answer: string;
}
