import {
  ASSESSMENT_ERROR,
  ASSESSMENT_QUESTIONS,
  ASSESSMENT_RECORD,
  CANDIDATE_INTERVIEW_LISTING,
  CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING,
  CURRENT_QUESTION_INDEX,
  JOB_ID_FOR_UPSKILL,
  QUESTION_INDEX,
  RESET_CANDIDATE_STATE,
  SEARCHED_MATCHING_JOBS,
  SET_CANDIDATE_LOADING,
  SET_JOBS_LOADING,
  SET_MATCHING_JOBS,
  TENANT_INFO,
  TENANT_INFO_DETAILS,
  TENANT_INFO_LOADING,
  TOGGLE_JOB_FAV_STATUS_LOADING,
  UPDATE_ASSESSMENT_RECORD,
  UPDATE_FAV_JOBS_STATUS,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING,
  INTERVIEW_LISTING_PAGINATION,
  TOP_MATCHING_JOBS,
  MATCHING_JOBS,
  MATCHING_JOBS_LOADING,
  MATCHING_JOBS_TITLE,
  ASSESSMENT_QUESTIONNAIRES,
  ASSESSMENT_QUESTIONNAIRES_ENGLISH,
  ASSESSMENT_QUESTIONNAIRES_ENGLISH_UPDATE,
  ASSESSMENT_QUESTIONNAIRES_ANNOTATION,
  ASSESSMENT_QUESTIONNAIRES_ANNOTATION_UPDATE,
  APP_ENG_ASSESSMENT_CANCELLED,
  APP_ENG_ASSESSMENT_COMPLETED,
  APP_ENG_CURRENT_QUESTION_INDEX,
  APP_ASSESSMENT_SS,
  APP_ANNOTATION_ASSESSMENT_CANCELLED,
  APP_ANNOTATION_ASSESSMENT_COMPLETED,
  APP_ANNOTATION_CURRENT_QUESTION_INDEX,
  SCREEN_RECORDING_SUPPORTED,
  CAMERA_RECORDING_SUPPORTED,
  ENG_ASSESSMENT_COMPLETED,
  ENG_ASSESSMENT_CANCELLED,
  ANNOTATION_ASSESSMENT_CANCELLED,
  ANNOTATION_ASSESSMENT_COMPLETED,
  SCREEN_RECORDING_PERMISSION_GRANTED,
  CAMERA_RECORDING_PERMISSION_GRANTED
} from "@/store/modules/candidates/constants";
import {
  AppAnnotationAssessment,
  AppAssessments,
  AppEnglishAssessment,
  AssessmentRecord,
  CandidateInterviewsListing,
  CandidateState,
  MatchingJob,
  MatchingJobs,
  SearchedMatchingJobsPayload,
  TenantInfo,
  TopMatchingJobs,
  UpdateAppAnnotationAssessmentPayload,
  UpdateAssessmentQuestionPayload,
  UpdateFavJobsStatus
} from "@/store/modules/candidates/interfaces";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";
import store from "@/store";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import { FavoriteJobs } from "@/interfaces/responses/favorite_jobs";
import { PrincipleAssessments } from "@/interfaces/data_objects/principle_assessments";
import { AssessmentQuestions } from "@/interfaces/responses/assessment_questions";
import { ViewAssessmentsOptions } from "@/interfaces/candidate/canidate_assessments";

export default {
  [SET_CANDIDATE_LOADING]: (state: CandidateState, payload: boolean): void => {
    state.loading = payload;
  },
  [SET_JOBS_LOADING]: (state: CandidateState, payload: boolean): void => {
    state.jobs_loading = payload;
  },
  [UPSKILL_COURSES_LOADING]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.upskill_courses_loading = payload;
  },
  [SET_MATCHING_JOBS]: (
    state: CandidateState,
    payload: MatchingJob[]
  ): void => {
    state.matching_jobs = payload;
  },
  [SEARCHED_MATCHING_JOBS]: (
    state: CandidateState,
    payload: SearchedMatchingJobsPayload
  ): void => {
    state.searched_matching_jobs = payload;
  },
  [UPSKILL_COURSES]: (
    state: CandidateState,
    payload: UpskillCourses.UpskillCoursesRoot | null
  ): void => {
    state.upskill_courses = payload;
  },
  [JOB_ID_FOR_UPSKILL]: (
    state: CandidateState,
    payload: number | null
  ): void => {
    state.job_id_for_upskill = payload;
  },
  [RESET_CANDIDATE_STATE]: (state: CandidateState): void => {
    state.loading = false;
    state.jobs_loading = false;
    state.matching_jobs = [];
    state.upskill_courses = null;
    state.upskill_courses_loading = false;
    state.job_id_for_upskill = null;
    state.toggle_job_fav_status_loading = false;
    state.searched_matching_jobs = {
      jobs: [],
      title: "",
      active: false
    };
    state.interviews = {
      search_job_loading: false,
      listing: null,
      pagination: 1
    };
    state.tenant_info = {
      error: true,
      loading: true,
      details: null,
      progress_value: 0,
      total_questions: 100,
      assessment_questions: {
        assessmentProgress: {
          shortscaleComplete: false,
          assessmentComplete: false,
          fractionComplete: 0
        },
        questions: []
      },
      assessment_record: [],
      question_index: 0
    };
    state.candidate_jobs = {
      matching_jobs: {
        loading: false,
        results: [],
        total: 0,
        limit: 12,
        page: 1
      },
      top_matching_jobs: {
        loading: false,
        results: [],
        total: 0
      }
    };
    state.app_assessments = {
      english: null,
      annotation: null,
      ss: []
    };
    state.screen_recording_supported = true;
    state.camera_recording_supported = true;
  },
  /**
   * Set toggle job fav status loading
   * @param {CandidateState} state
   * @param {boolean} payload => true/false
   */
  [TOGGLE_JOB_FAV_STATUS_LOADING]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.toggle_job_fav_status_loading = payload;
  },
  /**
   * Update all matching jobs fav status.
   * if payload.searched_matching_jobs = true => updated searched matching jobs status
   * else update matching jobs fav status
   * @param state
   * @param {UpdateFavJobsStatus} payload
   */
  [UPDATE_FAV_JOBS_STATUS]: (
    state: CandidateState,
    payload: UpdateFavJobsStatus
  ): void => {
    const uid = store.getters[`auth/${GET_USER_DETAILS}`].id;
    const data = state.candidate_jobs.matching_jobs.results.map((job) => {
      const job_exist = payload.fav_jobs.find((val: FavoriteJobs) => {
        return val.job_id === job.jid && val.user_id === uid;
      });
      if (job_exist)
        return {
          ...job,
          is_favorite: !job.is_favorite
        };
      else return job;
    });
    state.candidate_jobs.matching_jobs.results = data;
  },
  [CANDIDATE_INTERVIEW_LISTING]: (
    state: CandidateState,
    payload: CandidateInterviewsListing[] | null
  ): void => {
    state.interviews.listing = payload;
  },
  [CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.interviews.search_job_loading = payload;
  },
  [TENANT_INFO_LOADING]: (state: CandidateState, payload: boolean): void => {
    state.tenant_info.loading = payload;
  },
  [TENANT_INFO_DETAILS]: (
    state: CandidateState,
    payload: PrincipleAssessments | null
  ): void => {
    state.tenant_info.details = payload;
  },
  [TENANT_INFO]: (state: CandidateState, payload: TenantInfo): void => {
    state.tenant_info = payload;
  },
  [ASSESSMENT_QUESTIONS]: (
    state: CandidateState,
    payload: AssessmentQuestions.Root
  ): void => {
    state.tenant_info.assessment_questions = payload;
  },
  [ASSESSMENT_RECORD]: (
    state: CandidateState,
    payload: AssessmentRecord[]
  ): void => {
    state.tenant_info.assessment_record = payload;
  },
  [QUESTION_INDEX]: (state: CandidateState, value: number): void => {
    state.tenant_info.question_index = value;
  },
  [UPDATE_ASSESSMENT_RECORD]: (
    state: CandidateState,
    payload: AssessmentRecord
  ): void => {
    state.tenant_info.assessment_record[state.tenant_info.question_index] =
      payload;
  },
  [CURRENT_QUESTION_INDEX]: (state: CandidateState, payload: number): void => {
    if (state.tenant_info.details)
      state.tenant_info.details.question_index = payload;
  },
  [ASSESSMENT_ERROR]: (state: CandidateState, payload: boolean): void => {
    state.tenant_info.error = payload;
  },
  [INTERVIEW_LISTING_PAGINATION]: (
    state: CandidateState,
    page: number
  ): void => {
    state.interviews.pagination = page;
  },
  // Candidate Jobs
  [TOP_MATCHING_JOBS]: (
    state: CandidateState,
    payload: TopMatchingJobs
  ): void => {
    state.candidate_jobs.top_matching_jobs = payload;
  },
  [MATCHING_JOBS]: (state: CandidateState, payload: MatchingJobs): void => {
    state.candidate_jobs.matching_jobs = payload;
  },
  [MATCHING_JOBS_LOADING]: (state: CandidateState, payload: boolean): void => {
    state.candidate_jobs.matching_jobs.loading = payload;
  },
  [MATCHING_JOBS_TITLE]: (state: CandidateState, payload: string): void => {
    state.candidate_jobs.matching_jobs.job_title = payload;
  },
  [ASSESSMENT_QUESTIONNAIRES]: (
    state: CandidateState,
    payload: AppAssessments
  ): void => {
    state.app_assessments = payload;
  },
  // Setting english assessment
  [ASSESSMENT_QUESTIONNAIRES_ENGLISH]: (
    state: CandidateState,
    payload: AppEnglishAssessment
  ): void => {
    state.app_assessments.english = payload;
  },
  // Setting annotation assessment
  [ASSESSMENT_QUESTIONNAIRES_ANNOTATION]: (
    state: CandidateState,
    payload: AppAnnotationAssessment
  ): void => {
    state.app_assessments.annotation = payload;
  },
  [ASSESSMENT_QUESTIONNAIRES_ENGLISH_UPDATE]: (
    state: CandidateState,
    payload: UpdateAssessmentQuestionPayload
  ): boolean => {
    if (!state.app_assessments.english) return false;
    if (payload.filter === ViewAssessmentsOptions.WRITING) {
      if (!state.app_assessments.english.written) return false;
      const question = state.app_assessments.english.written.find((val) => {
        return val.id === payload.question_id;
      });
      if (question) {
        question.answer = payload.answer;
        return true;
      }
      return false;
    } else if (payload.filter === ViewAssessmentsOptions.SPEAKING) {
      if (!state.app_assessments.english.speaking) return false;
      const question = state.app_assessments.english.speaking.find((val) => {
        return val.id === payload.question_id;
      });
      if (question) {
        question.answer = payload.answer;
        return true;
      }
      return false;
    }
    return false;
  },
  [ASSESSMENT_QUESTIONNAIRES_ANNOTATION_UPDATE]: (
    state: CandidateState,
    payload: UpdateAppAnnotationAssessmentPayload
  ): boolean => {
    if (
      !state.app_assessments ||
      !state.app_assessments.annotation ||
      !state.app_assessments.annotation.questions
    )
      return false;
    const question = state.app_assessments.annotation.questions.find((val) => {
      return val.id === payload.question_id;
    });
    if (question) {
      question.answer = payload.answer;
      return true;
    }
    return false;
  },
  // Set inception eng assessment canceleld status to true/false,
  [APP_ENG_ASSESSMENT_CANCELLED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    if (!state.app_assessments.english) return;
    state.app_assessments.english.cancelled = payload;
  },
  // Set inception eng assessment completed status to true/false,
  [APP_ENG_ASSESSMENT_COMPLETED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    if (!state.app_assessments.english) return;
    state.app_assessments.english.completed = payload;
  },
  // Set inception eng assessment current question index
  [APP_ENG_CURRENT_QUESTION_INDEX]: (
    state: CandidateState,
    payload: number
  ): void => {
    if (!state.app_assessments.english) return;
    state.app_assessments.english.current_question_index = payload;
  },
  [APP_ASSESSMENT_SS]: (state: CandidateState, payload: File): void => {
    state.app_assessments.ss.push(payload);
  },
  // Set inception annotation assessment canceleld status to true/false,
  [APP_ANNOTATION_ASSESSMENT_CANCELLED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    if (!state.app_assessments.annotation) return;
    state.app_assessments.annotation.cancelled = payload;
  },
  // Set inception annotation assessment completed status to true/false,
  [APP_ANNOTATION_ASSESSMENT_COMPLETED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    if (!state.app_assessments.annotation) return;
    state.app_assessments.annotation.completed = payload;
  },
  // Set inception annotation assessment current question index
  [APP_ANNOTATION_CURRENT_QUESTION_INDEX]: (
    state: CandidateState,
    payload: number
  ): void => {
    if (!state.app_assessments.annotation) return;
    state.app_assessments.annotation.current_question_index = payload;
  },
  [SCREEN_RECORDING_SUPPORTED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.screen_recording_supported = payload;
  },
  [CAMERA_RECORDING_SUPPORTED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.camera_recording_supported = payload;
  },
  [ENG_ASSESSMENT_COMPLETED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.eng_assessment_completed = payload;
  },
  [ENG_ASSESSMENT_CANCELLED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.eng_assessment_cancelled = payload;
  },
  [ANNOTATION_ASSESSMENT_CANCELLED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.annotation_assessment_cancelled = payload;
  },
  [ANNOTATION_ASSESSMENT_COMPLETED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.annotation_assessment_completed = payload;
  },
  [SCREEN_RECORDING_PERMISSION_GRANTED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.screen_recording_permission_granted = payload;
  },
  [CAMERA_RECORDING_PERMISSION_GRANTED]: (
    state: CandidateState,
    payload: boolean
  ): void => {
    state.camera_recording_permission_granted = payload;
  }
};
