import {
  ACTION_CANDIDATE,
  GET_ALL_CANDIDATES
} from "@/store/modules/recruiter_admin/constants";
import {
  ActionCandidatePayload,
  AllCandidates,
  GetAllCandidatesPayload
} from "@/store/modules/recruiter_admin/interfaces";
import {
  action_candidate,
  get_all_candidates
} from "@/store/apis/recruiter_admin";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { Commit } from "vuex";
import i18n from "@/i18n";

export default {
  [GET_ALL_CANDIDATES]: async (
    { commit }: { commit: Commit },
    payload: GetAllCandidatesPayload
  ): Promise<boolean | AllCandidates> => {
    try {
      const response = await get_all_candidates(payload);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return false;
    }
  },
  [ACTION_CANDIDATE]: async (
    { commit }: { commit: Commit },
    payload: ActionCandidatePayload
  ): Promise<boolean> => {
    try {
      await action_candidate(payload);
      commit(ROOT_NOTIFICATION, i18n.t("shared.email-send"), { root: true });
      return true;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return false;
    }
  }
};
