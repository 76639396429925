import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const candidate_inception_assessment_routes: RouteConfig = {
  path: "assessments",
  component: () =>
    import(
      "@/views/candidate/inception/assessments/InceptionAssessmentWrapper.vue"
    ),
  meta: {
    user_state: [UserState.CANDIDATE]
  },
  children: [
    {
      path: "",
      redirect: {
        name: "english-assessment"
      },
      meta: {
        user_state: [UserState.CANDIDATE]
      }
    },
    {
      path: "english",
      component: () =>
        import(
          "@/views/candidate/inception/assessments/InceptionAssessmentEnglishWrapper.vue"
        ),
      meta: {
        user_state: [UserState.CANDIDATE]
      },
      // Sub routes
      children: [
        {
          path: "",
          name: "english-assessment",
          component: () =>
            import(
              "@/views/candidate/inception/assessments/inception_english_assessment/InceptionEnglishAssessmentIndex.vue"
            ),
          meta: {
            user_state: [UserState.CANDIDATE]
          }
        }
      ]
    },
    // Inception Annotation Assessment Main Route (/assessments/annotation)
    {
      path: "annotation",
      component: () =>
        import(
          "@/views/candidate/inception/assessments/InceptionAssessmentAnnotationWrapper.vue"
        ),
      meta: {
        user_state: [UserState.CANDIDATE]
      },
      // Sub routes
      children: [
        {
          path: "",
          name: "annotation-assessment",
          component: () =>
            import(
              "@/views/candidate/inception/assessments/inception_annotation_assessment/InceptionAnnotationAssessmentIndex.vue"
            ),
          meta: {
            user_state: [UserState.CANDIDATE]
          }
        }
      ]
    }
  ]
};
