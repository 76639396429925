import getters from "@/store/modules/recruiter_admin/getters";
import mutations from "@/store/modules/recruiter_admin/mutations";
import actions from "@/store/modules/recruiter_admin/actions";
import { RecruiterAdmin } from "@/store/modules/recruiter_admin/interfaces";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";

export default {
  namespaced: true,
  state: (): RecruiterAdmin => ({
    candidates: {
      total: 0,
      results: [],
      pagination: 1
    },
    view_candidate_data: null,
    view_candidate_filter: ViewCandidatesOptions.REGISTERED,
    al_ain_users: true
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
