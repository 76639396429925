import { User } from "@/interfaces/data_objects/user";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";

export interface AuthState {
  loading: boolean;
  auth_error: string | null;
  user: User | null;
  company: Company | null;
  user_state: UserState;
  user_profile: Candidates.CandidateDetails | null;
  reset_password_payload: ForgotPasswordPayload | null;
  email_for_reset_password: string | null;
}

export enum UserState {
  UNAUTHENTICATED,
  EMAIL_VERIFICATION_PENDING,
  EMAIL_VERIFICATION_COMPLETED,
  ONBOARDING_PENDING,
  ONBOARDING_COMPLETED,
  RECRUITER,
  RECRUITER_ADMIN,
  CANDIDATE_PROFILE_PENDING,
  CANDIDATE,
  RESET_PASSWORD,
  RESET_PASSWORD_COMPLETED,
  ROOT_ERROR,
  PENDING_VERIFICATION
}

export interface Company {
  avatar_uri: string | null;
  company_description: string | null;
  company_id: number;
  company_industry: string | null;
  company_location: string | null;
  company_name: string;
  company_size: string | null;
  company_website: string | null;
  s3_path: CompanyS3Path;
}

export interface CompanyS3Path {
  resume_upload_path: string;
  course_upload_path: string;
  jobs_upload_path: string;
  avatar_upload_path: string;
  job_embs_upload_path: string;
  cv_book_upload_path?: string;
  degree_program_upload_path?: string;
  highlight_awards_upload_path?: string;
  public_resume_upload_path?: string;
  interview_audio_upload_path?: string;
  interview_recording_upload_path?: string;
  assessment_results_upload_path?: string;
  assessments_path?: string;
  knowledge_base?: {
    path: string;
    files: KnowledgeBaseFiles[];
    info: {
      flag: boolean;
      msg: string;
    };
  };
  assessment_setting?: AppAssessments.CompanySetting;
}

export interface KnowledgeBaseFiles {
  uri: string;
  location: string;
  created_at: Date;
  filename: string;
  uploaded_by: {
    id: number;
    first_name: string;
    last_name?: string;
    email: string;
  };
}

export interface LoginActionPayload {
  email: string;
  password: string;
  remember_me: boolean;
}

export interface SignupActionPayload {
  first_name: string;
  last_name: string | null;
  mobile_number?: string;
  email: string;
  password: string;
}

export interface SetUserPropertyPayload {
  obj_name: string;
  value: string | null | boolean | number;
}

export interface InitUserResponse extends User {
  companyDetail?: Company;
}

export interface ResetPasswordPayload {
  password: string;
  reset_password_token: string;
  email: string;
}

export interface ForgotPasswordPayload {
  email: string;
  token: string;
}

export interface UpdateCompanyPayload {
  email: string;
}

export interface RemoveAwardPayload {
  id: number;
  uid: string;
}

export interface RefreshTokenPayload {
  refresh_token: string;
}

export interface EmailVerifiedPayload {
  email: string;
  token: string;
}
