import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const settings_routes: RouteConfig = {
  path: "/settings",
  component: () => import("@/views/settings/SettingsWrapper.vue"),
  meta: {
    user_state: [
      UserState.CANDIDATE,
      UserState.RECRUITER,
      UserState.RECRUITER_ADMIN,
      UserState.CANDIDATE_PROFILE_PENDING
    ]
  },
  children: [
    {
      path: "",
      redirect: {
        name: "accounts-setting"
      }
    },
    {
      path: "account",
      name: "accounts-setting",
      component: () => import("@/views/settings/Account.vue"),
      meta: {
        user_state: [
          UserState.CANDIDATE,
          UserState.RECRUITER,
          UserState.RECRUITER_ADMIN,
          UserState.CANDIDATE_PROFILE_PENDING
        ]
      }
    },
    {
      path: "assessments",
      name: "assessments-setting",
      component: () => import("@/views/settings/AssessmentsSetting.vue"),
      meta: {
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    },
    {
      path: "*",
      component: () => import("../views/NotFound.vue"),
      meta: {
        user_state: [
          UserState.UNAUTHENTICATED,
          UserState.EMAIL_VERIFICATION_PENDING,
          UserState.EMAIL_VERIFICATION_COMPLETED,
          UserState.ONBOARDING_PENDING,
          UserState.RECRUITER,
          UserState.RECRUITER_ADMIN,
          UserState.CANDIDATE
        ]
      }
    }
  ]
};
